import {fetchDelete, fetchDeletePic, fetchGet, fetchPost, fetchPut} from "./index";
import {
  DealerListQueryParams,
  DealerDetail,
  DealerListProps,
  DealerChangeHistoryProps,
} from "@/views/DealerMasterData/Dealer/types";
import {
  Bu,
  DealerGroup,
  DealerType,
} from "@/views/DealerMasterData/Region/types";
import {
  NewRegionProps,
  RegionListProps,
  UpdateRegionProps,
} from "@/views/DealerMasterData/Region/types";
import axios from "axios";

export const getAllBu = (): Promise<Bu[]> => {
  return fetchGet("/dapi/allBu");
};

export const getDealerGroup = (): Promise<DealerGroup[]> => {
  return fetchGet("/dapi/allGroup");
};

export const getDealerType = (): Promise<DealerType[]> => {
  return fetchGet("/dapi/allType");
};

export const newRegion = (reqBody: NewRegionProps): Promise<any> => {
  return fetchPost("/dapi/newRegion", reqBody);
};

export const updateRegion = (reqBody: UpdateRegionProps): Promise<any> => {
  return fetchPut("/dapi/updateRegion", reqBody);
};

export const deleteRegion = (params: { id: string }): Promise<any> => {
  return fetchDeletePic(`/dapi/deleteRegion/${params.id}`);
};

export const getRegion = (params: {
  buId: number;
}): Promise<RegionListProps[]> => {
  return fetchGet("/dapi/dealerRegion", { params });
};

export const getRegionDetail = (params: {
  id: number;
}): Promise<RegionListProps[]> => {
  return fetchGet(`/dapi/region/${params.id}`);
};

//dealer

export const getDealerList = (
  params: DealerListQueryParams
): Promise<DealerListProps[]> => {
  return fetchGet("/dapi/dealerInfo/list", { params });
};

export const getDealerDetail = (params: {
  dealerId: string;
}): Promise<DealerDetail> => {
  return fetchGet(`/dapi/dealerInfo/${params.dealerId}`);
};

export const updateDealerInfo = (reqBody: DealerDetail): Promise<any> => {
  return fetchPut("/dapi/dealerInfo", reqBody);
};

export const getDealerHistory = (params: {
  dealerId: string;
  page: number;
  size: number;
}): Promise<DealerChangeHistoryProps[]> => {
  return fetchGet(`/dapi/dealerHistory/${params.dealerId}`, {
    params: { page: params.page, size: params.size },
  });
};

export const checkDealer = (params: {
  dealerInfoId: string;
  dealerCode: string;
}): Promise<any> => {
  return fetchGet(
    `/dapi/dealerInfo/${params.dealerInfoId}/${params.dealerCode}`
  );
};

// dealer block
export const getDealerBlockList = (params = {}): Promise<any> => {
  return fetchGet("/dapi/dealerBlock/list", { params });
};

// 上传后保存
export const saveBlockRecord = (params = {}): Promise<any> => {
  return fetchPost("/dapi/dealerBlock/blockRecord", params);
};

// 获取dealerblockcode
export const getBlockCodeList = () => {
  return fetchGet("/dapi/dealerBlock/allDealerBlockCode");
};

// 获取下载文件历史链接
export const getDealerBlockLink = (
  documentId: string,
  type: string,
  fileName: string
) => {
  return fetchGet(
    `/dapi/dealerBlock/download/${documentId}/${type}/${fileName}/link`
  );
};

// 删除block数据
export const deleteBlockRecord = (id: string) => {
  return fetchDelete(`/dapi/dealerBlock/blockRecord/${id}`);
};

// view的table数据
export const getDealerBlockView = (blockCode: string) => {
  return fetchGet(`/dapi/dealerBlock/blockRecord/${blockCode}`);
};

// 更改状态
export const updateAuditState = (params = {}) => {
  return fetchPost(`/dapi/dealerBlock/blockRecord/audit`, {}, { params });
};

export const exportDealerInfo = (params: any) => {
  return axios.post(
    "/dapi/dealerInfo/export",
    {},
    { params, responseType: "blob" }
  );
};
